const hero = document.querySelector('.hero');
const heroTextContainer = document.querySelector('.hero__textContainer');
const heroInformation = document.querySelector('.hero__information');
const heroInformationText = document.querySelector('.hero__information p');
const heroForm = document.querySelector('.hero__form');
const headerTitle = document.querySelector('.header__title');
const guest = document.querySelector('#guest');
const starsTwo = document.querySelector('#starsTwo');
const starsOne = document.querySelector('#starsOne');
const reviewTwo = document.querySelector('#reviewTwo');
const reviewOne = document.querySelector('#reviewOne');
const guesText = document.querySelector('.about__guestText')
const cards = document.querySelectorAll('.card');
const services = document.querySelectorAll('.service');





const tl = gsap.timeline({ defaults: { ease: 'power1.inOut' } });
gsap.set([guest, ...starsTwo.children, ...starsOne.children, reviewOne, reviewTwo], {autoAlpha: 0, opacity: 0})

gsap.registerPlugin(ScrollTrigger);
tl.set(	[
		...heroTextContainer.children,
		heroInformation,
		heroInformationText,

	],{	visibility: 'hidden',	});

tl.fromTo(hero, {	width: "0%",	opacity: 0,}, {duration: 2, width: '100%', opacity: 1})
	.fromTo(heroTextContainer.children,	{	opacity: 0,	x: '-=400',	},{	duration: 1.5,x: '+=400',	visibility: 'visible', opacity: 1, stagger: {	amount: 1,},})
	.fromTo(heroInformation,{	width: 0,	opacity: 0,	height: '60',	},{	duration: 1, width: '100%',	visibility: 'visible', opacity: 1,}, '-=.5'	)
	.fromTo(heroInformationText,{	opacity: 0,	x: '-=90',},{	duration: 0.7, opacity: 1, visibility: 'visible',	x: '+=90',},'-=.5'	)
  .from(heroForm, { zIndex: -100, duration: 1, opacity: 0, y: '-=20'})
  .from(headerTitle, { duration: 1.3, y:'-=100', opacity: 0}, '-=5')


const guestSvgAnimation = gsap.timeline();
guestSvgAnimation.fromTo(guest, {
  x: '+=400'
}, {
  duration: 1.4,
  x:'-=400',
  opacity: 1,
  autoAlpha: 1
})
.fromTo(reviewOne, {y:'-=150'}, {y:'+=150', duration: .7, opacity: 1, autoAlpha: 1}, '-=0.5')
.fromTo(starsOne.children, {y: '+=15'}, {duration: 0.6, opacity: 1, autoAlpha: 1, y:'-=15', stagger: {amount: .3}})
.fromTo(reviewTwo, {y:'-=150'}, {y:'+=150', duration: .7, opacity: 1, autoAlpha: 1}, '-=0.6')
.fromTo(starsTwo.children, {y: '+=15'}, {duration: 0.6, opacity: 1, autoAlpha: 1, y:'-=15', stagger: {amount: .3}}, '-=0.5')
.from(guesText, {y: '+=30', opacity: 0, duration: 0.8}, '-=0.8')


ScrollTrigger.create({
  trigger: '.about',
  animation: guestSvgAnimation,
  start: 'top 50%'
})


gsap.from(cards, {
  opacity: 0,
  x:'-=40',
  y: '+=40',
  duration: .8,
  stagger: {
    amount: 0.5,
  },
  scrollTrigger: {
    trigger: '.card',

    start: 'top 60%',
  }
})

gsap.from(services, {
  opacity: 0,
  y:'+=40',
duration: .8,
stagger: {
  amount: .8
},
scrollTrigger: {
  trigger: '.service',
  start: 'top 60%',
}
})